import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import classNames from 'classnames'
import pttLogo from "../../img/logo_ptt_2.png"
import devLogo from "../../img/sds-background.svg"
import ytLogo from "../../img/yontem-logo2.png"
import kpLogo from "../../img/logo_kokpit-2.png"

import { observable, action } from 'mobx'

interface IPromptProps extends IBaseProps {
    iconName: string,
    prompt: string,
    height?: string | number
}

class DriverPrompt extends Component<IPromptProps> {

    delayTimer

    @observable delayFinished: boolean = false

    @action finishDelay = () => this.delayFinished = true

    componentDidMount() {
        this.delayTimer = setTimeout(this.finishDelay, 500)
    }

    componentWillUnmount() {
        clearTimeout(this.delayTimer)
    }

    render() {
        const t = this.props.t!
        return (
            this.delayFinished ?
                <div className={
                    classNames(
                        "d-flex flex-column justify-content-center",
                        {
                            "align-items-start": this.props.prompt === "pleaseChooseDrivers" && process.env.REACT_APP_SV === 'ptt2',
                            "align-items-center": process.env.REACT_APP_SV !== 'ptt2' || this.props.prompt !== "pleaseChooseDrivers",
                            "home-screen": this.props.prompt === "pleaseChooseDrivers", "screen-height-limit": !this.props.height
                        }
                    )
                }
                    style={{ height: this.props.height || undefined }}>
                    <div className="hcard">
                        <div className="d-flex flex-column justify-content-center align-items-center card-body">
                            {process.env.REACT_APP_SV !== 'kp' && process.env.REACT_APP_SV !== 'ptt' && (this.props.prompt === "pleaseChooseDrivers" ?
                                <div>
                                    <img alt="logo" src={process.env.REACT_APP_SV === 'ptt2' ? pttLogo : process.env.REACT_APP_SV === 'yt' ? ytLogo : devLogo} />
                                </div>
                                :
                                < i className={`fa ${this.props.iconName} fa-5x text-muted mb-3`} />)
                            }
                            {this.props.height || this.props.prompt !== "pleaseChooseDrivers" ?
                                <>
                                    <span className="text-muted">{t(this.props.prompt)}</span>
                                    {this.props.prompt === "pleaseChooseDrivers" && <span className="text-muted">{t("pleaseChooseDriversSub")}</span>}
                                </>
                                :
                                <>
                                    <span className="h3">{t(this.props.prompt)}</span>
                                    {this.props.prompt === "pleaseChooseDrivers" && <span className="h4">{t("pleaseChooseDriversSub")}</span>}
                                </>}
                        </div>

                    </div>
                </div>
                :
                null
        )
    }
}

export default inject("store")(withTranslation("main")(observer(DriverPrompt)))