import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { RouterView } from 'mobx-state-router'
import { Container } from 'reactstrap'
import { IBaseProps } from "../../types"
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'

import DriverPicker from '../../components/DriverPicker/DriverPicker'

import Page404 from "../../views/Pages/Page404/Page404"
import Login from "../../views/Pages/Login/Login"

import Loader from '../../utils/Loader'
import ActionListModal from '../../components/ActionListModal/ActionListModal'
import GradeModal from '../../components/GradeModal/GradeModal'
import ViolationModal from '../../components/ViolationModal/ViolationModal'
import DatePickerModal from '../../components/DatePickerModal/DatePickerModal'
import checkSelectedDrivers from '../../utils/checkSelectedDrivers'

import Dashboard from "../../views/Dashboard/Dashboard"
import checkSuperUser from '../../utils/checkSuperUser'

import Summary from "../../views/Dashboard/Summary"
import BestDrivers from "../../views/Dashboard/BestDrivers"
import Violations from "../../views/Dashboard/Violations"
import BestDepartments from "../../views/Dashboard/BestDepartments"

const Driver = checkSelectedDrivers(Loader({
    loader: () => import('../../views/Driver/Report' /* webpackChunkName: 'driverReport' */),
}))

const DriverDetails = checkSelectedDrivers(Loader({
    loader: () => import('../../views/Driver/Details' /* webpackChunkName: 'driverDetails' */),
}))

const DriverViolations = checkSelectedDrivers(Loader({
    loader: () => import('../../views/Driver/Violations' /* webpackChunkName: 'driverViolations' */),
}))

const League = checkSelectedDrivers(Loader({
    loader: () => import('../../views/Driver/League' /* webpackChunkName: 'driverLeague' */),
}))

const RiskLeague = checkSelectedDrivers(Loader({
    loader: () => import('../../views/Driver/RiskLeague' /* webpackChunkName: 'driverRiskLeague' */),
}))

const WorstDriverLeague = Loader({
    loader: () => import('../../views/Driver/WorstDriverLeague' /* webpackChunkName: 'WorstDriverLeague' */),
})


const LeagueTest = checkSelectedDrivers(Loader({
    loader: () => import('../../views/Driver/LeagueTest' /* webpackChunkName: 'driverLeagueTest' */),
}))

const GradeReport = checkSelectedDrivers(Loader({
    loader: () => import('../../views/Driver/GradeReport' /* webpackChunkName: 'driverGradeReport' */),
}))

const FleetReport = checkSelectedDrivers(Loader({
    loader: () => import('../../views/Fleet/Report' /* webpackChunkName: 'fleetReport' */),
}))

const ReportSettingsPage = checkSuperUser(Loader({
    loader: () => import('../../views/Pages/ReportSettingsPage' /* webpackChunkName: 'reportSettings' */),
}))

const viewMap = {
    dashboard: <Dashboard />,
    // @ts-ignore
    login: <Login />,
    // @ts-ignore
    driver: <Driver />,
    // @ts-ignore
    fleet: <FleetReport />,
    // @ts-ignore
    driverViolations: <DriverViolations />,
    // @ts-ignore
    driverDetails: <DriverDetails />,
    // @ts-ignore
    riskLeague: <RiskLeague />,
    // @ts-ignore
    league: <League />,
    // @ts-ignore
    leagueTest: <LeagueTest />,
    worstdriverleague: <WorstDriverLeague />,
    summary: <Summary />,
    bestdrivers: <BestDrivers />,
    violations: <Violations />,
    bestdepartments: <BestDepartments />,
    // @ts-ignore
    ecoGradeReport: <GradeReport type="eco" key="eco" />,
    // @ts-ignore
    safetyGradeReport: <GradeReport type="safety" key="safety" />,
    // @ts-ignore
    tiredReport: <GradeReport type="tired" key="tired" />,
    // @ts-ignore
    reportSettings: <ReportSettingsPage />,
    404: <Page404 />
}

class Full extends Component<IBaseProps> {
    render() {
        return (
            <div className="app">
                <Header />
                <div className="app-body">
                    <Sidebar {...this.props} />
                    <main className="main pt-2">
                        <Container fluid={true}>
                            <RouterView routerStore={this.props.store!.routing} viewMap={viewMap} />
                        </Container>
                    </main>
                </div>
                <DriverPicker />

                <Modals />

            </div>
        )
    }
}

const Modals: React.FC<{}> = (props) => {
    return <>
        <DatePickerModal />
        <ActionListModal />
        <GradeModal />
        <ViolationModal />
    </>
}

export default inject("store")(observer(Full))
