/* tslint:disable */
/* eslint-disable */
/**
 * Etl Sds Api
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * The version of the OpenAPI document: 1.123.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
import {
    CustomerNameDto,
    CustomerNameDtoFromJSON,
    CustomerNameDtoFromJSONTyped,
    CustomerNameDtoToJSON,
    DriverNameDto,
    DriverNameDtoFromJSON,
    DriverNameDtoFromJSONTyped,
    DriverNameDtoToJSON,
    PersonelDepartmentNameDto,
    PersonelDepartmentNameDtoFromJSON,
    PersonelDepartmentNameDtoFromJSONTyped,
    PersonelDepartmentNameDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerTreeDto
 */
export interface CustomerTreeDto {
    /**
     * 
     * @type {Array<CustomerNameDto>}
     * @memberof CustomerTreeDto
     */
    customers?: Array<CustomerNameDto> | null;
    /**
     * 
     * @type {Array<PersonelDepartmentNameDto>}
     * @memberof CustomerTreeDto
     */
    personelDepartments?: Array<PersonelDepartmentNameDto> | null;
    /**
     * 
     * @type {Array<DriverNameDto>}
     * @memberof CustomerTreeDto
     */
    drivers?: Array<DriverNameDto> | null;
}

export function CustomerTreeDtoFromJSON(json: any): CustomerTreeDto {
    return CustomerTreeDtoFromJSONTyped(json, false);
}

export function CustomerTreeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerTreeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customers': !exists(json, 'customers') ? undefined : (json['customers'] === null ? null : (json['customers'] as Array<any>).map(CustomerNameDtoFromJSON)),
        'personelDepartments': !exists(json, 'personelDepartments') ? undefined : (json['personelDepartments'] === null ? null : (json['personelDepartments'] as Array<any>).map(PersonelDepartmentNameDtoFromJSON)),
        'drivers': !exists(json, 'drivers') ? undefined : (json['drivers'] === null ? null : (json['drivers'] as Array<any>).map(DriverNameDtoFromJSON)),
    };
}

export function CustomerTreeDtoToJSON(value?: CustomerTreeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customers': value.customers === undefined ? undefined : (value.customers === null ? null : (value.customers as Array<any>).map(CustomerNameDtoToJSON)),
        'personelDepartments': value.personelDepartments === undefined ? undefined : (value.personelDepartments === null ? null : (value.personelDepartments as Array<any>).map(PersonelDepartmentNameDtoToJSON)),
        'drivers': value.drivers === undefined ? undefined : (value.drivers === null ? null : (value.drivers as Array<any>).map(DriverNameDtoToJSON)),
    };
}


