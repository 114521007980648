import DriverModel from "./DriverModel"
import { computed, action, observable } from "mobx"
import { Department } from "../../api"
import RootStore from "../RootStore"
import { PersonelDepartmentNameDto } from "../../api3"

class DepartmentModel {
    customerId: number
    departmentId: number
    name: string
    drivers: DriverModel[] = []
    @observable isOpen = false
    rootStore: RootStore

    constructor(customer: Department | PersonelDepartmentNameDto, rootStore: RootStore) {
        this.rootStore = rootStore

        if (typeof customer.customerId === "number")
            this.customerId = customer.customerId
        else
            this.customerId = parseInt(customer.customerId!, 10)


        if ((customer as Department).departmentId !== undefined)
            this.customerId = parseInt((customer as Department).departmentId!)
        if ((customer as PersonelDepartmentNameDto).id !== undefined)
            this.departmentId = (customer as PersonelDepartmentNameDto).id!

        this.name = customer.name!
    }

    @computed get isFullySelected() {
        if (!this.drivers || this.drivers.length === 0) {
            return false
        }
        else {
            return this.drivers.every((driver) => {
                if (driver.selected) {
                    return true
                }
                return false
            })
        }
    }

    @computed get isPartiallySelected() {
        if (!this.drivers || this.drivers.length === 0) {
            return false
        }
        else {
            return this.drivers.some((driver) => {
                if (driver.selected) {
                    return true
                }
                return false
            })
        }
    }

    @computed get visiable() {
        if (!this.drivers || this.drivers.length === 0) {
            return false
        }
        else {
            return this.drivers.some((driver) => {
                if (driver.visiable) {
                    return true
                }
                return false
            })
        }
    }


    @action toggleSelected = () => {
        const val = !this.isFullySelected
        this.drivers.forEach(d => { if (d.visiable) { d.setSelected(val) } })
    }

    @action _setSelected = (val) => {
        this.drivers.forEach(d => { if (d.visiable) { d.setSelected(val) } })
    }
    @action toggleOpen = () => {
        this.isOpen = !this.isOpen
    }
}

export default DepartmentModel