/* tslint:disable */
/* eslint-disable */
/**
 * Etl Sds Api
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * The version of the OpenAPI document: 1.123.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    CustomerTreeDto,
    CustomerTreeDtoFromJSON,
    CustomerTreeDtoToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

/**
 * no description
 */
export class SdsApi extends runtime.BaseAPI {

    /**
     * Sds için müşteri listesi (Login)
     */
    async getCustomerTreeRaw(): Promise<runtime.ApiResponse<CustomerTreeDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sds/Sds/customerTree`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerTreeDtoFromJSON(jsonValue));
    }

    /**
     * Sds için müşteri listesi (Login)
     */
    async getCustomerTree(): Promise<CustomerTreeDto> {
        const response = await this.getCustomerTreeRaw();
        return await response.value();
    }

}
