import { Component } from 'react'
import {
    Col,
    Row,
} from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import Spinner from '../../components/Spinner/Spinner'





class Summary extends Component<IBaseProps> {




    constructor(props: any) {
        super(props)

    }



    render() {
        const store = this.props.store!
        if (!store.ui.treeReady) {

            return <div className="animated fadeIn screen-height-limit">
                <Spinner />
            </div>

        }

        return (
            <div className="animated fadeIn screen-height-limit">
                <Row className="justify-content-center">
                    <Col xs="auto" className="">
                        <div className="brand"></div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="6" lg="3">
                        <div className="summary-box pink" onClick={() => { this.props.store!.routing.goTo("dashboard") }}>
                            <span className="title">Filo Raporu</span>

                        </div>
                    </Col>

                    <Col xs="12" sm="6" lg="3">
                        <div className="summary-box pink" onClick={() => { this.props.store!.routing.goTo("bestdrivers") }}>
                            <span className="title">En İyi Sürücüler</span>

                        </div>
                    </Col>


                    <Col xs="12" sm="6" lg="3">
                        <div className="summary-box pink" onClick={() => { this.props.store!.routing.goTo("bestdepartments") }}>
                            <span className="title">En İyi Departmanlar</span>

                        </div>
                    </Col>

                    <Col xs="12" sm="6" lg="3">
                        <div className="summary-box pink" onClick={() => { this.props.store!.routing.goTo("violations") }}>
                            <span className="title">En Çok Yapılan İhlaler</span>

                        </div>
                    </Col>



                </Row>


            </div >
        )
    }

    renderArrows = (val) => {
        return <>
            {val < 0 && <i className="fa fa-angle-double-down text-red  mr-1" aria-hidden="true" />}
            {val > 0 && <i className="fa fa-angle-double-up text-blue  mr-1" aria-hidden="true" />}
            {val === 0 && <i className="fa fa-minus text-gray-200  mr-1" aria-hidden="true" />}
        </>

    }
}

export default inject("store")(withTranslation("main")(observer(Summary)))
