import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './App'
import { unregister } from './serviceWorker'
import * as Sentry from '@sentry/react'
import 'mobx-react-lite/batchingForReactDom'
// @ts-ignore
import { Chart } from 'react-chartjs-2'
import { Integrations } from "@sentry/tracing"

// @ts-ignore
import * as url from "url"
import config from './config'
// @ts-ignore
url.URLSearchParams = URLSearchParams

Chart.pluginService.register({
    beforeDraw(chart) {
        if (chart.config.options.elements.center) {
            // Get ctx from string
            let ctx = chart.chart.ctx

            // Get options from the center object in options
            let centerConfig = chart.config.options.elements.center
            let fontStyle = centerConfig.fontStyle || 'Arial'
            let txt = centerConfig.text
            let color = centerConfig.color || '#000'
            let sidePadding = centerConfig.sidePadding || 20
            let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            // Start with a base font of 30px
            ctx.font = "30px " + fontStyle

            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            let stringWidth = ctx.measureText(txt).width
            let elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated

            // Find out how much the font can grow in width.
            let widthRatio = elementWidth / stringWidth
            let newFontSize = Math.floor(30 * widthRatio)
            let elementHeight = (chart.innerRadius * 2)

            // Pick a new font size so it will not be larger than the height of label.
            let fontSizeToUse = Math.min(newFontSize, elementHeight)

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
            let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2)
            ctx.font = fontSizeToUse + "px " + fontStyle
            ctx.fillStyle = color

            // Draw text in center
            ctx.fillText(txt, centerX, centerY)
        }
    }
})


if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        tunnel: "https://sentunnel.iot.lemonsoft.com.tr/sentunnel",
        dsn: 'https://4c3becb73ef949ec8fc5fcdd879f1d74@sentry.lemonsoft.com.tr/3',
        release: process.env.REACT_APP_VERSION,
        environment: `${process.env.NODE_ENV}-${process.env.REACT_APP_SV}`,
        integrations: [new Integrations.BrowserTracing({
            tracingOrigins: [config.api_url.replace("https://", "")],
        })], tracesSampleRate: 1.0, // Be sure to lower this in production
        denyUrls: [
            /extensions\//i,
            /^chrome:\/\//i
        ]
    })
}

ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
)
unregister()
