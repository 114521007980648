import DepartmentModel from "./DepartmentModel"
import { computed, action, observable } from "mobx"
import { Customer } from "../../api"
import RootStore from "../RootStore"
import { CustomerNameDto } from "../../api3"

class CustomerModel {
    customerId: number
    parentId: number
    customerName: string
    departments: DepartmentModel[] = []
    childs: CustomerModel[] = []
    @observable isOpen = false
    rootStore: RootStore

    constructor(customer: Customer | CustomerNameDto, rootStore: RootStore) {
        this.rootStore = rootStore
        if ((customer as Customer).customerId !== undefined)
            this.customerId = (customer as Customer).customerId
        if ((customer as Customer).customerName !== undefined)
            this.customerName = (customer as Customer).customerName!
        if ((customer as CustomerNameDto).id !== undefined)
            this.customerId = (customer as CustomerNameDto).id!
        if ((customer as CustomerNameDto).name !== undefined)
            this.customerName = (customer as CustomerNameDto).name!
        this.parentId = customer.parentId!
    }


    @computed get visiableDeps() {
        if (!this.departments || this.departments.length === 0) {
            return false
        }
        else {
            return this.departments.some((department) => {
                if (department.visiable) {
                    return true
                }
                return false
            })
        }
    }

    @computed get isSelectable() {
        if (!this.childs || this.childs.length === 0) {
            return true
        }
        else {
            return false
        }
    }

    @computed get visiableChilds() {
        if (!this.childs || this.childs.length === 0) {
            return false
        }
        else {
            return this.childs.some((department) => {
                if (department.visiable) {
                    return true
                }
                return false
            })
        }
    }

    @computed get visiable() {
        return this.visiableChilds || this.visiableDeps
    }

    @computed get fullySelectedDeps() {
        if (!this.departments || this.departments.length === 0) {
            return false
        }
        else {
            return this.departments.every((department) => {
                if (department.isFullySelected) {
                    return true
                }
                return false
            })
        }
    }

    @computed get fullySelectedChilds() {
        if (!this.childs || this.childs.length === 0) {
            return false
        }
        else {
            return this.childs.every((child) => {
                if (child.isFullySelected) {
                    return true
                }
                return false
            })
        }
    }

    @computed get partiallySelectedDeps() {
        if (!this.departments || this.departments.length === 0) {
            return false
        }
        else {
            return this.departments.some((department) => {
                if (department.isPartiallySelected || department.isFullySelected) {
                    return true
                }
                return false
            })
        }
    }

    @computed get partiallySelectedChilds() {
        if (!this.childs || this.childs.length === 0) {
            return false
        }
        else {
            return this.childs.some((child) => {
                if (child.isPartiallySelected || child.isFullySelected) {
                    return true
                }
                return false
            })
        }
    }

    @computed get isFullySelected() {
        if (this.childs && this.childs.length > 0 && this.departments && this.departments.length > 0) {
            return this.fullySelectedChilds && this.fullySelectedDeps
        }
        else if (this.departments && this.departments.length > 0) {
            return this.fullySelectedDeps
        } else {
            return this.fullySelectedChilds
        }

    }

    @computed get isPartiallySelected() {
        return this.partiallySelectedChilds || this.partiallySelectedDeps
    }

    @action toggleSelected = () => {
        const val = !this.isFullySelected
        this._setSelected(val)
    }

    @action _setSelected = (val) => {
        this.childs.forEach(d => { if (d.visiable) { d._setSelected(val) } })
        this.departments.forEach(d => { if (d.visiable) { d._setSelected(val) } })
    }

    @action toggleOpen = () => {
        this.isOpen = !this.isOpen
    }
}

export default CustomerModel