/* tslint:disable */
/* eslint-disable */
/**
 * Etl Sds Api
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * The version of the OpenAPI document: 1.123.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import moment from "moment";

import {
    DriverLeagueGradeListDto,
    DriverLeagueGradeListDtoFromJSON,
    DriverLeagueGradeListDtoToJSON,
    DriverLeagueMonthlyListDto,
    DriverLeagueMonthlyListDtoFromJSON,
    DriverLeagueMonthlyListDtoToJSON,
    DriverLeagueMonthlySummaryDto,
    DriverLeagueMonthlySummaryDtoFromJSON,
    DriverLeagueMonthlySummaryDtoToJSON,
    ValidationProblemDetails,
    ValidationProblemDetailsFromJSON,
    ValidationProblemDetailsToJSON,
} from '../models';

export interface GetDriverMonthlyLeagueByDriverRequest {
    month: moment.Moment;
    driverId: number;
}

export interface GetDriverMonthlyLeagueListRequest {
    month: moment.Moment;
    customerId?: number;
}

export interface GetLastSevenMonthlyGradeByDriverIdRequest {
    driverId: number;
    customerId?: number;
}

export interface GetMonthlySummaryRequest {
    driverId: number;
    customerId?: number;
}

/**
 * no description
 */
export class DriverLeagueApi extends runtime.BaseAPI {

    /**
     * Sürünün Aylık sürücü ligi kaydı (Login)
     */
    async getDriverMonthlyLeagueByDriverRaw(requestParameters: GetDriverMonthlyLeagueByDriverRequest): Promise<runtime.ApiResponse<DriverLeagueMonthlyListDto>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month', 'Required parameter requestParameters.month was null or undefined when calling getDriverMonthlyLeagueByDriver.');
        }

        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId', 'Required parameter requestParameters.driverId was null or undefined when calling getDriverMonthlyLeagueByDriver.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = (requestParameters.month as any).toISOString();
        }

        if (requestParameters.driverId !== undefined) {
            queryParameters['driverId'] = requestParameters.driverId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sds/DriverLeague/monthlyByDriver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverLeagueMonthlyListDtoFromJSON(jsonValue));
    }

    /**
     * Sürünün Aylık sürücü ligi kaydı (Login)
     */
    async getDriverMonthlyLeagueByDriver(requestParameters: GetDriverMonthlyLeagueByDriverRequest): Promise<DriverLeagueMonthlyListDto> {
        const response = await this.getDriverMonthlyLeagueByDriverRaw(requestParameters);
        return await response.value();
    }

    /**
     * Aylık sürücü ligi listesi (Login)
     */
    async getDriverMonthlyLeagueListRaw(requestParameters: GetDriverMonthlyLeagueListRequest): Promise<runtime.ApiResponse<Array<DriverLeagueMonthlyListDto>>> {
        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month', 'Required parameter requestParameters.month was null or undefined when calling getDriverMonthlyLeagueList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = (requestParameters.month as any).toISOString();
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sds/DriverLeague/monthly`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverLeagueMonthlyListDtoFromJSON));
    }

    /**
     * Aylık sürücü ligi listesi (Login)
     */
    async getDriverMonthlyLeagueList(requestParameters: GetDriverMonthlyLeagueListRequest): Promise<Array<DriverLeagueMonthlyListDto>> {
        const response = await this.getDriverMonthlyLeagueListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sürünün 7 Aylık ortalamalarını getirir (Login)
     */
    async getLastSevenMonthlyGradeByDriverIdRaw(requestParameters: GetLastSevenMonthlyGradeByDriverIdRequest): Promise<runtime.ApiResponse<Array<DriverLeagueGradeListDto>>> {
        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId', 'Required parameter requestParameters.driverId was null or undefined when calling getLastSevenMonthlyGradeByDriverId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverId !== undefined) {
            queryParameters['driverId'] = requestParameters.driverId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sds/DriverLeague/LastSevenMonthlyGradeByDriverId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverLeagueGradeListDtoFromJSON));
    }

    /**
     * Sürünün 7 Aylık ortalamalarını getirir (Login)
     */
    async getLastSevenMonthlyGradeByDriverId(requestParameters: GetLastSevenMonthlyGradeByDriverIdRequest): Promise<Array<DriverLeagueGradeListDto>> {
        const response = await this.getLastSevenMonthlyGradeByDriverIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sürünün Aylık sürücü ligi özeti (Login)
     */
    async getMonthlySummaryRaw(requestParameters: GetMonthlySummaryRequest): Promise<runtime.ApiResponse<DriverLeagueMonthlySummaryDto>> {
        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId', 'Required parameter requestParameters.driverId was null or undefined when calling getMonthlySummary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.driverId !== undefined) {
            queryParameters['driverId'] = requestParameters.driverId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/sds/DriverLeague/MonthlySummary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverLeagueMonthlySummaryDtoFromJSON(jsonValue));
    }

    /**
     * Sürünün Aylık sürücü ligi özeti (Login)
     */
    async getMonthlySummary(requestParameters: GetMonthlySummaryRequest): Promise<DriverLeagueMonthlySummaryDto> {
        const response = await this.getMonthlySummaryRaw(requestParameters);
        return await response.value();
    }

}
