/* tslint:disable */
/* eslint-disable */
/**
 * Etl Sds Api
 * Token gönderimi \"Authorization: Bearer {token}\" headeri ile veya \"access_token\" query parametresi ile yapılabilir
 *
 * The version of the OpenAPI document: 1.123.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import moment from "moment";
/**
 * 
 * @export
 * @interface PersonelDepartmentNameDto
 */
export interface PersonelDepartmentNameDto {
    /**
     * 
     * @type {number}
     * @memberof PersonelDepartmentNameDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonelDepartmentNameDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonelDepartmentNameDto
     */
    customerId?: number;
}

export function PersonelDepartmentNameDtoFromJSON(json: any): PersonelDepartmentNameDto {
    return PersonelDepartmentNameDtoFromJSONTyped(json, false);
}

export function PersonelDepartmentNameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonelDepartmentNameDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
    };
}

export function PersonelDepartmentNameDtoToJSON(value?: PersonelDepartmentNameDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'customerId': value.customerId,
    };
}


