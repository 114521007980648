import React, { Component } from 'react'
import { Map, TileLayer, Marker, CircleMarker, Polyline, LayersControl, LayerGroup, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import { inject, observer } from 'mobx-react'
import { observable } from "mobx"
import { withTranslation } from 'react-i18next'
import { IBaseProps } from "../../types"
import actionTypeToString from "../../utils/actionTypeToString"
import moment from "../../utils/moment"

import { iconA, iconB, iconAccel, iconBrake, iconHardLeft, iconHardRight, iconSpeeding } from "./MapIcons"
import { Action } from '../../api'
import EmptyPrompt from '../EmptyPrompt/EmptyPrompt'
import compose from '../../utils/compose'

// webpack fix
import * as L from 'leaflet'
import marker from 'leaflet/dist/images/marker-icon.png'
import marker2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl: marker2x,
    iconUrl: marker,
    shadowUrl: markerShadow
})
// webpackfix end
const violationIcons = [iconA, iconAccel, iconBrake, iconHardLeft, iconHardRight, iconSpeeding]

interface IMapProps extends IBaseProps {
    focusedAction?: Action
}

class MapC extends Component<IMapProps> {

    @observable zoom: number = this.props.store!.ui.mapZoom

    parsePolyline = (s): Array<[number, number]> => {
        try {
            return JSON.parse(s)
        } catch (error) {
            return []
        }
    }
    render() {
        const t = this.props.t!
        const store = this.props.store!.ui
        const focusedAction = this.props.focusedAction

        let center = store.mapFocusCircle
        if (focusedAction) {
            center = [focusedAction.lat!, focusedAction.lon!]
        }
        else if (!center) {
            if (store.coordinates.length > 0) {
                center = store.coordinates[0]
            }
        }
        return (
            <React.Fragment>
                {center ?
                    <Map bounds={store.coordinatesBounds} animate center={center} zoom={store.coordinatesBounds ? undefined : this.zoom} onViewportChanged={(data) => { if (data.zoom) { this.zoom = data.zoom } }} attributionControl={false}>
                        <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name="Harita">
                                <TileLayer
                                    attribution='&copy; <a href="https://www.jetmaps.com">jetmaps</a>'
                                    url='https://www.jetmaps.com/api/maps.php?style=style0&api_key=demo&z={z}&x={x}&y={y}'
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Uydu">
                                <TileLayer
                                    attribution='&copy; <a href="https://www.jetmaps.com">jetmaps</a>'
                                    url='https://www.jetmaps.com/api/maps.php?style=satellite&api_key=demo&z={z}&x={x}&y={y}'
                                />
                            </LayersControl.BaseLayer>
                        </LayersControl>

                        {store.mapFocusCircle && <CircleMarker center={store.mapFocusCircle} radius={5} color={"red"}></CircleMarker>}
                        <Polyline positions={store.coordinates}></Polyline>

                        {
                            focusedAction ?
                                <LayerGroup>
                                    {store.coordinates.length > 0 && <Marker icon={iconA} position={store.coordinates[0]}></Marker>}
                                    {store.coordinates.length > 1 && <Marker icon={iconB} position={store.coordinates[store.coordinates.length - 1]}></Marker>}
                                    <Marker icon={violationIcons[focusedAction.type!]} position={[focusedAction.lat!, focusedAction.lon!]}>
                                        <Tooltip>{`${t(actionTypeToString(focusedAction.type))}(${focusedAction.violationValue})`}</Tooltip>
                                    </Marker>
                                    {focusedAction.type === 5 && focusedAction.polyline && <Polyline positions={this.parsePolyline(focusedAction.polyline)} color="#f86c6b"></Polyline>}
                                </LayerGroup>
                                :
                                <LayersControl position="bottomleft">
                                    <LayersControl.Overlay name="Başlangıç/Bitiş" checked>
                                        <LayerGroup>
                                            <Marker icon={iconA} position={store.coordinates[0]}></Marker>
                                            {store.coordinates.length > 1 && <Marker icon={iconB} position={store.coordinates[store.coordinates.length - 1]}></Marker>}
                                        </LayerGroup>

                                    </LayersControl.Overlay>
                                    <LayersControl.Overlay name={t(actionTypeToString(1))} checked>
                                        <LayerGroup>
                                            {store.actionList.filter((a) => a.type === 1).map((action) => action.lat && action.lon && (
                                                <Marker onClick={(e) => this.props.store!.chart.setTracker(moment.utc(action.startDateTime).toDate())} key={action.actionId} icon={iconAccel} position={[action.lat!, action.lon!]}>
                                                    <Tooltip>{`${t(actionTypeToString(action.type))}(${action.violationValue})`}</Tooltip>
                                                </Marker>
                                            ))}
                                        </LayerGroup>
                                    </LayersControl.Overlay>
                                    <LayersControl.Overlay name={t(actionTypeToString(2))} checked>
                                        <LayerGroup>
                                            {store.actionList.filter((a) => a.type === 2).map((action) => action.lat && action.lon && (
                                                <Marker onClick={(e) => this.props.store!.chart.setTracker(moment.utc(action.startDateTime).toDate())} key={action.actionId} icon={iconBrake} position={[action.lat!, action.lon!]}>
                                                    <Tooltip>{`${t(actionTypeToString(action.type))}(${action.violationValue})`}</Tooltip>
                                                </Marker>
                                            ))}
                                        </LayerGroup>
                                    </LayersControl.Overlay>
                                    <LayersControl.Overlay name={t(actionTypeToString(3))} checked>
                                        <LayerGroup>
                                            {store.actionList.filter((a) => a.type === 3).map((action) => action.lat && action.lon && (
                                                <Marker onClick={(e) => this.props.store!.chart.setTracker(moment.utc(action.startDateTime).toDate())} key={action.actionId} icon={iconHardLeft} position={[action.lat!, action.lon!]}>
                                                    <Tooltip>{`${t(actionTypeToString(action.type))}(${action.violationValue})`}</Tooltip>
                                                </Marker>
                                            ))}
                                        </LayerGroup>
                                    </LayersControl.Overlay>
                                    <LayersControl.Overlay name={t(actionTypeToString(4))} checked>
                                        <LayerGroup>
                                            {store.actionList.filter((a) => a.type === 4).map((action) => action.lat && action.lon && (
                                                <Marker onClick={(e) => this.props.store!.chart.setTracker(moment.utc(action.startDateTime).toDate())} key={action.actionId} icon={iconHardRight} position={[action.lat!, action.lon!]}>
                                                    <Tooltip>{`${t(actionTypeToString(action.type))}(${action.violationValue})`}</Tooltip>
                                                </Marker>
                                            ))}
                                        </LayerGroup>
                                    </LayersControl.Overlay>
                                    <LayersControl.Overlay name={t(actionTypeToString(5))} checked>
                                        <LayerGroup>
                                            {store.actionList.filter((a) => a.type === 5).map((action) => action.lat && action.lon && (
                                                <React.Fragment key={action.actionId + "frag"}>
                                                    <Marker onClick={(e) => this.props.store!.chart.setTracker(moment.utc(action.startDateTime).toDate())} key={action.actionId} icon={iconSpeeding} position={[action.lat!, action.lon!]}>
                                                        <Tooltip>{`${t(actionTypeToString(action.type))}(${action.violationValue})`}</Tooltip>
                                                    </Marker>
                                                    {action.polyline && <Polyline key={action.actionId + "line"} positions={this.parsePolyline(action.polyline)} color="#f86c6b"></Polyline>}
                                                </React.Fragment>
                                            ))}
                                        </LayerGroup>
                                    </LayersControl.Overlay>
                                </LayersControl>
                        }


                    </Map >
                    :
                    <EmptyPrompt prompt="noMapData" iconName={"fa-map-signs"} height="100%" />
                }
            </React.Fragment>
        )
    }
}

export default compose(
    inject("store"),
    withTranslation("main"),
    observer,
)(MapC)