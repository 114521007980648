const dev = {
    root: '/',
    base_url: 'localhost:3000',
    // api_url: "http://localhost:17302",
    api_url: "https://sds.devtakip.com/api",
    //api2_url: "http://localhost:5050",
    api2_url: "https://sds.devtakip.com/v2",
    //api3_url: "http://localhost:5050",
    api3_url: "https://api-kokpit.tnbmobil.com",
    // api_url: "https://sds.pttteknoloji.com.tr/api",
    login_redirect: "https://web.devtakip.com/",
    brand_url: "https://www.devtakip.com/",
    homepage: process.env.REACT_APP_SV === 'ptt' ? "summary" : "dashboard",
}


const _config = process.env.NODE_ENV === 'production' ?
    (process.env.REACT_APP_SV === 'sds' ?
        {
            ...dev,
            base_url: 'sds.devtakip.com',
            api_url: "https://sds.devtakip.com/api",
            api2_url: "https://sds.devtakip.com/v2",
            api3_url: "https://api-kokpit.tnbmobil.com",
            login_redirect: "https//web.devtakip.com/",
            brand_url: "https://www.devtakip.com/"
        } :
        process.env.REACT_APP_SV === 'sds-test' ?
            {
                ...dev,
                base_url: 'sdstest.devtakip.com',
                api_url: "https://sdstest.devtakip.com/api",
                login_redirect: "https://web.devtakip.com/",
                brand_url: "https://www.devtakip.com/"
            } :
            (process.env.REACT_APP_SV === 'yt' ?
                {
                    ...dev,
                    base_url: 'sds.yontemgps.com',
                    api_url: "https://sds.devtakip.com/api",
                    api2_url: "https://sds.devtakip.com/v2",
                    api3_url: "https://api-kokpit.tnbmobil.com",
                    login_redirect: "http://esgs.yontemgps.com/",
                    brand_url: "http://www.yontemgps.com/"
                } :
                process.env.REACT_APP_SV === 'kp' ?
                    {
                        ...dev,
                        base_url: 'sds.tnbmobil.com',
                        api_url: "https://sds.tnbmobil.com/api",
                        api2_url: "https://sds.tnbmobil.com/v2",
                        api3_url: "https://api-kokpit.tnbmobil.com",
                        login_redirect: "https://kokpit.tnbmobil.com/",
                        brand_url: "https://kokpit.tnbmobil.com/"
                    } :
                    {
                        ...dev,
                        base_url: 'sds.pttteknoloji.com.tr',
                        api_url: "https://sds.pttteknoloji.com.tr/api",
                        api2_url: "https://sds.pttteknoloji.com.tr/v2",
                        api3_url: "https://api-takip.tnbmobil.com",
                        login_redirect: "https://takip.pttteknoloji.com.tr/",
                        brand_url: "http://www.pttteknoloji.com.tr/"
                    })
    )
    :
    dev

export default {
    ..._config
}

