import UiStore from './UiStore'
import { DriverApi, Configuration } from "../api"
import ChartStore from './ChartStore'
import ReportStore from "./ReportStore"

import { RouterState, RouterStore } from 'mobx-state-router'
import routes from '../routes'
import { action, observable } from 'mobx'
import ActivityStore from './ActivityStore'
import ModalStore from './ModalStore'
import config from '../config'
import ReportSettingsStore from './ReportSettingsStore'
import * as Sentry from '@sentry/react'
import { SDSApiApi, Configuration as Configuration2 } from '../api2'
import { SdsApi, Configuration as Configuration3 } from '../api3'

const notFound = new RouterState('404')

class RootStore {
    routing: RouterStore
    ui: UiStore
    api: DriverApi
    api2: SDSApiApi
    api3: SdsApi
    chart: ChartStore
    report: ReportStore
    reportSettings: ReportSettingsStore
    @observable auth: boolean = false
    @observable authReady: boolean = true
    @observable crm: boolean = false
    @observable superUser: boolean = false
    @observable email: string = ""
    @observable customerId: number = -1
    redirect: RouterState
    activity: ActivityStore
    modal: ModalStore

    constructor() {
        this.api = new DriverApi(new Configuration({ basePath: config.api_url }))
        this.api2 = new SDSApiApi(new Configuration2({ basePath: config.api2_url }))
        this.api3 = new SdsApi(new Configuration3({ basePath: config.api3_url }))

        this.auth = false
        this.ui = new UiStore(this)
        this.activity = new ActivityStore(this)
        this.chart = new ChartStore(this)
        this.report = new ReportStore(this)
        this.modal = new ModalStore(this)
        this.reportSettings = new ReportSettingsStore(this)

        this.routing = new RouterStore(this, routes, notFound)
        this.routing.setErrorHook((error) => {
            console.error(error) // eslint-disable-line no-console
            Sentry.captureException(error)

        })
    }

    @action login = (token) => {
        let self = this
        this.auth = false
        this.authReady = false
        return new Promise(
            (resolve, reject) => {
                self.api.checkToken(token).then(action("login", (data: any) => {
                    if (data) {
                        self.auth = true
                        self.authReady = true
                        self.crm = data.crm
                        self.superUser = data.superUser
                        self.customerId = data.customerId
                        self.email = data.email
                        try {
                            localStorage.token = token
                        } catch {
                            // pass
                        }

                        self.api = new DriverApi(new Configuration({ accessToken: token, basePath: config.api_url }))
                        self.api2 = new SDSApiApi(new Configuration2({ apiKey: "bearer " + token, basePath: config.api2_url }))
                        self.api3 = new SdsApi(new Configuration3({ apiKey: "bearer " + token, basePath: config.api3_url }))

                        resolve(null)
                    } else {
                        self.auth = false
                        self.authReady = true
                        try {
                            localStorage.removeItem("token")
                        } catch {
                            // pass
                        }
                        resolve(null)
                    }
                }))
            })
    }

    check401 = (response) => {
        if (response.status === 401) {
            if (this.authReady) {
                this.logout()
            }
        } else {
            throw response

        }
    }

    @action setRedirect = (toState: RouterState) => {
        this.redirect = toState
    }

    @action logout = () => {
        this.auth = false
        this.authReady = true

        try {
            localStorage.removeItem("token")
        } catch {
            // pass
        }
        let toState: RouterState = new RouterState("login")
        this.routing.goTo(toState)
    }

}


export default RootStore
