import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'

import { IBaseProps } from "../../types"

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    ListGroup,
    ListGroupItem,
    InputGroup,
    InputGroupAddon,
    Input
} from 'reactstrap'
import { action } from 'mobx'
import { RouterState } from 'mobx-state-router'
import Datepicker from "../Header/Datepicker"
import CustomerModel from '../../stores/Models/CustomerModel'
import DepartmentModel from '../../stores/Models/DepartmentModel'
import DriverModel from '../../stores/Models/DriverModel'
import Loading from '../Loading'
import { observer as observerHook } from "mobx-react-lite"

const DriverSearchComp: React.FC = inject("store")(withTranslation("main")(observer(((props) => {
    const store = props.store!
    return <InputGroup>
        <InputGroupAddon addonType="prepend">Arama</InputGroupAddon>
        <Input name="text" value={store.ui.driverSearchText} onChange={action("driverSearch", (e: any) => { store.ui.driverSearchText = e.target.value })} />
    </InputGroup>
})) as React.FC<IBaseProps>)) as unknown as React.FC


const DriverComp: React.FC<{ driver: DriverModel, level?: number, parent: DepartmentModel | CustomerModel }> = observerHook(((props) => {
    const driver = props.driver
    const parent = props.parent
    const level = props.level ? props.level : 0

    return <>{driver.visiable && <ListGroupItem
        key={"driver-item-" + driver.id}
        action
        onClick={() => driver.toggleSelected()}
        color={parent.isPartiallySelected ? "info" : undefined}
        className="driver-picker-driver-item"
        style={{ borderLeftWidth: 14 * level + "px" }}
    >
        <i className={"fa fa-fw fa-lg " + (driver.selected ? "fa-check-square-o" : "fa-square-o") + " mr-2"} />
        <span >{driver.name}</span>
    </ListGroupItem>}
    </>
}) as React.FC<IBaseProps & { driver: DriverModel, level?: number, parent: DepartmentModel | CustomerModel }>) as unknown as React.FC<{ driver: DriverModel, level?: number, parent: DepartmentModel | CustomerModel }>

const DepartmentComp: React.FC<{ department: DepartmentModel, level?: number, parent: CustomerModel }> = observerHook(((props) => {
    const dept = props.department
    const level = props.level ? props.level : 0
    return <>
        {dept.visiable && (dept.departmentId === -1 ?
            dept.drivers && dept.drivers.map(ch => <DriverComp key={"driver-" + ch.id} driver={ch} level={level} parent={dept} />)
            :
            <>
                <ListGroupItem
                    key={"dept-item-" + dept.customerId + "-" + dept.departmentId}
                    action
                    onMouseDown={() => dept.toggleOpen()}
                    color={dept.isPartiallySelected ? "info" : undefined}
                    className="driver-picker-dept-item"
                    style={{ borderLeftWidth: 14 * level + "px" }}
                >
                    <React.Fragment>
                        <i className={"fa fa-fw fa-lg " + (dept.isFullySelected ? "fa-check-square-o" : "fa-square-o") + " mr-2"}
                            onMouseDown={(e) => { e.stopPropagation(); dept.toggleSelected() }}
                        />
                        <b>{dept.name}</b>
                        <i className={"fa fa-lg " + (dept.isOpen ? "fa-caret-up" : "fa-caret-down") + " float-right"} />
                    </React.Fragment>

                </ListGroupItem>
                {dept.isOpen && dept.drivers && dept.drivers.map(ch => <DriverComp key={"driver-" + ch.id} driver={ch} level={level + 1} parent={dept} />)}
            </>)
        }
    </>
}) as React.FC<IBaseProps & { department: DepartmentModel, level?: number, parent: CustomerModel }>) as unknown as React.FC<{ department: DepartmentModel, level?: number, parent: CustomerModel }>


const CustomerComp: React.FC<{ customer: CustomerModel, level?: number, parent?: CustomerModel }> = observerHook(((props) => {
    const customer = props.customer
    const level = props.level ? props.level : 0
    return <>
        {(level === 0 || customer.visiable) && <><ListGroupItem
            key={"customer-item-" + customer.customerId}
            action
            onMouseDown={() => customer.toggleOpen()}
            color={customer.isPartiallySelected ? "info" : undefined}
            className="driver-picker-customer-item"
            style={level === 0 ? undefined : { borderLeftWidth: 14 * level + "px" }}
        >

            <React.Fragment>
                {customer.isSelectable && <i className={"fa fa-fw fa-lg " + (customer.isFullySelected ? "fa-check-square-o" : "fa-square-o") + " mr-2"}
                    onMouseDown={(e) => { e.stopPropagation(); customer.toggleSelected() }}
                />}
                <b><em>{customer.customerName}</em></b>
                {level !== 0 && <i className={"fa fa-lg " + ((level === 0 || customer.isOpen) ? "fa-caret-up" : "fa-caret-down") + " float-right"} />}
            </React.Fragment>

        </ListGroupItem>
            {(level === 0 || customer.isOpen) && customer.departments && customer.departments.map(ch => <DepartmentComp key={"dept-" + ch.customerId + "-" + ch.departmentId} department={ch} level={level + 1} parent={customer} />)}
            {(level === 0 || customer.isOpen) && customer.childs && customer.childs.map(ch => <CustomerComp key={"customer-" + ch.customerId} customer={ch} level={level + 1} parent={customer} />)}
        </>}
    </>
}) as React.FC<IBaseProps & { customer: CustomerModel, level?: number, parent?: CustomerModel }>) as unknown as React.FC<{ customer: CustomerModel, parent?: CustomerModel }>

class DriverPicker extends Component<IBaseProps> {




    @action submit = () => {
        const store = this.props.store!
        let selectedDrivers = this.props.store!.ui.drivers.filter(d => d.selected)
        store.ui.datePicker.unit = store.ui.tempDatePicker.unit
        store.ui.datePicker.end = store.ui.tempDatePicker.end.clone()
        store.ui.datePicker.start = store.ui.tempDatePicker.start.clone()
        let oldParams = Object.assign({}, store.routing.routerState.queryParams)
        // @ts-ignore
        oldParams.date = JSON.stringify(store.ui.tempDatePicker.asJson)
        // @ts-ignore
        // oldParams.d = toJS(selectedDrivers.map((driver) => driver.id))

        if (selectedDrivers.length > 0) {
            store.ui.setDrivers(selectedDrivers.map((driver) => driver.id))
            let toState: RouterState = new RouterState(store.routing.getCurrentRoute().name, store.routing.routerState.params, oldParams)
            store.routing.goTo(toState)
            store.activity.clearFocusDriver()
            store.ui.toggleDriverPicker()
        }

    }



    render() {
        const t = this.props.t!
        const store = this.props.store!
        const toggle = store.ui.toggleDriverPicker
        return (
            <Modal
                isOpen={store.ui.isDriverPickerVisible}
                toggle={toggle}

                contentClassName="driver-picker-modal"
                size="md"
                className="mr-1"
            >
                <ModalBody >
                    <div className="col-12">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <span className="h5">Tarih Seçimi</span>
                            <Datepicker />
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center ">
                            <span className="h5">Sürücü/Grup Seçimi ({store.ui.driverCount})</span>
                            <Loading ready={store.ui.treeReady} object={store.ui.rootCustomer} emptyPromt="noDriverTree" icon="fa-users">
                                <DriverSearchComp />
                                <ListGroup className="mt-2 driver-picker-list">
                                    {store.ui.rootCustomer && <CustomerComp customer={store.ui.rootCustomer} />}
                                </ListGroup>
                            </Loading>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="driver-picker-footer">
                    <Button color="primary" onClick={this.submit} disabled={store.ui.driverCount < 1}>{t("choose")}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>{t("cancel")}</Button>
                </ModalFooter>
            </Modal >
        )
    }
}

export default inject("store")(withTranslation("main")(observer(DriverPicker)))