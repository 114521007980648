const nav = {
    items: [
        {
            name: 'Filo Raporu',
            routeName: "dashboard",
            icon: "fa fa-book"
        },
        process.env.REACT_APP_SV === 'ptt' ? {
            name: 'Özet Raporlar',
            routeName: "summary",
            icon: "fa fa-book"
        } : undefined,
        {
            name: 'Hareketler',
            routeName: "driverDetails",
            icon: "fa fa-map"
        },
        {
            name: 'Sürücü Ligi',
            routeName: "league",
            icon: "fa fa-trophy"
        },
        {
            name: 'Risk Ligi ',
            routeName: "riskLeague",
            icon: "fa fa-trophy",
        },
        {
            name: 'Kötü Sürücüler Ligi',
            routeName: "worstdriverleague",
            icon: "fa fa-trophy",
            crm: true
        },
        {
            name: 'Sürücü Karnesi',
            routeName: "driver",
            icon: "fa fa-id-card-o"
        },
        {
            name: 'Grup Raporu',
            routeName: "fleet",
            icon: "fa fa-book"
        },
        {
            name: 'İhlal Raporu',
            routeName: "driverViolations",
            icon: "fa fa-exclamation-triangle"
        },
        {
            name: 'Güvenlik Raporu',
            routeName: "safetyGradeReport",
            icon: "fa fa-shield"
        },
        {
            name: 'Eko-Sürüş Raporu',
            routeName: "ecoGradeReport",
            icon: "fa fa-leaf"
        },
        {
            name: 'Yorgunluk Raporu',
            routeName: "tiredReport",
            icon: "fa fa-bed"
        },
        {
            name: 'Rapor Ayarları',
            routeName: "reportSettings",
            icon: "fa fa-gear",
            superUser: true
        },
        process.env.REACT_APP_SV !== 'kp' && process.env.REACT_APP_SV !== 'ptt' ? {
            name: 'Çıkış',
            routeName: "logout",
            icon: "fa fa-sign-out"
        } : undefined,
    ]
}

export default nav
